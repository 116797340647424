import { isAndroid } from '../helpers/os';

export const allowedDebugLocations = ['localhost1', 'app22.skeddy.net'];

const urls = {
  map: '/map',
  rides: '/rides',
  settings: '/settings',
  registration: '/registration',
  start: '/',
  login: '/login',
  setApiUrl: '/set-api-url',
  debug: '/debug',
  credits: '/credits',
  creditsHistory: '/credits-history',
  skeddy: 'https://skeddy.net',
  creditsRules: '/credits-rules',
  addRide: '/add-ride',
  adminLogin: '/admin-login',
} as const;

export const isDebugHost = allowedDebugLocations.indexOf(window.location.hostname) !== -1;

export default urls;

export const helpVideoLink = 'https://www.youtube.com/channel/UClwgc6rK1qSaZ-93Rya9dWQ';

export const siteLink = 'https://skeddy.net';

export const supportLink = 'https://skeddy.zendesk.com/hc/en-us/requests/new';

export const instructionsLink = 'https://skeddy.zendesk.com/hc/en-us/articles/360041981974';

export const faqLink = 'https://skeddy.zendesk.com/hc/en-us/sections/360006773573-FAQ';

export const guidanceLink = 'https://skeddy.zendesk.com/hc/en-us/articles/360053072553';

export const fbLink = 'https://www.facebook.com/groups/skeddyappforlyftdrivers/';

export const localApiUrl = 'https://api2.skeddy.net/api/client';

export const apiUrl = 'https://api.skeddy.net/api/client';

export const baseUrl = isDebugHost ? localApiUrl : apiUrl;

export const getAppStoreUrl = () => {
  if (isAndroid) {
    return 'https://play.google.com/store/apps/details?id=net.skeddy.app';
  }

  return 'https://apps.apple.com/app/id1513441511';
};

export const getAppReviewStoreUrl = () => {
  if (isAndroid) {
    return 'https://play.google.com/store/apps/details?id=net.skeddy.app';
  }

  return 'https://apps.apple.com/app/id1513441511?action=write-review';
};

export const lyftApiUrl = isDebugHost ? 'https://api2.skeddy.net' : 'https://api.lyft.com';
