import { call, delay, fork, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { compareVersions } from 'compare-versions';

import { DestinationTimes } from 'common/getRideDestinationTime';
import API from 'api';
import storageService, { StorageKeys } from 'services/StorageService';
import {
  actionCreators,
  setIsReviewApp,
  setRideCreditsLoading,
  setShowUpdatePage,
  updateRideCredits,
} from 'store/app/acions';

import { messages } from '../../i18n';
import { logout } from '../user/actions';
import { nativeMessageService } from '../../services/NativeMessageService';
import {
  ValidationRetryService,
  validationRetryService,
} from '../../services/ValidationRetryService';
import { rollbar } from '../../config/rollbar';
import { AppState, isAndroidPurchase, isIosPurchase, SearchErrorStatusCodes } from '../interfaces';
import { eventsService } from '../../services/EventsService';
import { getRides } from '../rides/actions';
import { sendEvent } from '../../services/reactGa';
import { isAndroid, isDev } from '../../helpers/os';
import { iosVersion } from '../../config/appVersions';

function* getSearchStatus() {
  const user = yield select((app: AppState) => app.auth.user);

  if (!user) {
    return;
  }

  if (!user.user_id) {
    toast.error(messages.sessionTimeout);
    yield put(logout());
    return;
  }

  const response = yield call(API.search.get);

  switch (response.code) {
    case 200:
    case 202: {
      const { search_on, available_credits, is_agreed, search_error_status, client_message } =
        response;
      const isAgreed = is_agreed;

      const availableCredits = available_credits;

      if (client_message && search_error_status !== 0) {
        yield call(eventsService.showClientMessage, client_message, search_error_status);
      }

      nativeMessageService.sendSearchStatusMessage(search_on);
      nativeMessageService.sendExpiredTokenStatusMessage(
        search_error_status === SearchErrorStatusCodes.expiredToken
      );

      yield put(
        actionCreators.getSearchStatus.success(
          search_on,
          availableCredits,
          isAgreed,
          search_error_status
        )
      );

      if (availableCredits <= 0 && search_on) {
        sendEvent('Search_ShowBuyCreditsAlert');
        yield put(actionCreators.setRideCreditsAlert('search'));
        yield put(actionCreators.setSearchStatus.request(false));
      }
      break;
    }
    case 401: {
      toast.error(messages.sessionTimeout);
      yield put(logout());
      break;
    }
    case 422: {
      yield put(actionCreators.getSearchStatus.failure(response.errors));
      break;
    }
    default: {
      // toast.error(messages.serverError, { type: 'default' });
      yield put(actionCreators.getSearchStatus.failure({ errors: {} }));
      break;
    }
  }
}

function* agreedNewRides() {
  yield call(API.auth.agreed);
  yield put(actionCreators.getSearchStatus.request());
}

function* handleAppVersion({ payload }) {
  const { appVersion } = payload;

  const androidConfig = yield call(API.androidConfig.get);

  const isReviewApp = isAndroid && androidConfig?.allowLoginVersion === appVersion;

  yield put(setIsReviewApp(isReviewApp));

  const showUpdatePage = isAndroid
    ? // @ts-ignore
      compareVersions(androidConfig?.version, appVersion) > 0
    : compareVersions(iosVersion, appVersion) > 0;

  yield put(setShowUpdatePage(showUpdatePage));
}

function* toggleShowAllRides() {
  const { allRides } = yield select((app: AppState) => app.rides);
  const bookedRides = allRides.filter((ride) => ride.is_booked);

  if (allRides.length === bookedRides.length) {
    return;
  }

  yield put(getRides.request());
}

function* checkSearchAndRunCallback(action) {
  const { callback } = action.payload;
  const { isSearchOn, rideCredits, wizardStep } = yield select((app: AppState) => app.app);

  if (wizardStep === null && rideCredits !== null && rideCredits <= 0) {
    sendEvent('Settings_ShowBuyCreditsAlert');
    yield put(actionCreators.setRideCreditsAlert('settings'));
    return;
  }

  if (!isSearchOn && rideCredits !== null && rideCredits > 0) {
    yield put(actionCreators.setSearchStatus.request(true));
  }

  yield callback();
}

function* setSearchStatus({ payload }) {
  const { isSearchOn } = payload;
  const response = yield call(API.search.set, isSearchOn);

  switch (response.code) {
    case 200:
    case 202: {
      nativeMessageService.sendSearchStatusMessage(isSearchOn);
      yield put(actionCreators.setSearchStatus.success(isSearchOn));
      break;
    }
    case 401: {
      toast.error(messages.sessionTimeout);
      yield put(logout());
      break;
    }
    case 422: {
      yield put(actionCreators.setSearchStatus.failure(response.errors));
      break;
    }
    default: {
      // toast.error(messages.serverError, { type: 'default' });
      yield put(actionCreators.setSearchStatus.failure({ errors: {} }));
      break;
    }
  }
}

function* clearDestinationTimes() {
  const dayDelay = 1000 * 60 * 60 * 24;

  while (true) {
    try {
      yield delay(dayDelay);
      const destinationTimesStr = storageService.getItem(StorageKeys.destinationTimes);

      if (destinationTimesStr) {
        const destinationTimes: DestinationTimes = JSON.parse(destinationTimesStr);
        const currentTime = Math.round(+new Date() / 1000);

        Object.keys(destinationTimes).forEach((index) => {
          if (currentTime > destinationTimes[index].pickupTime) {
            delete destinationTimes[index];
          }
        });

        storageService.setItem(StorageKeys.destinationTimes, JSON.stringify(destinationTimes));
      }
    } catch (e) {
      console.log(e);
    }
  }
}

function* clearNotificationAuthToken() {
  const dayDelay = 1000 * 60 * 60 * 24;

  while (true) {
    yield delay(dayDelay);
    storageService.removeItem(StorageKeys.notificationAuthToken);
  }
}

function* watchValidationRetryItems() {
  const dayDelay = 1000 * 60 * ValidationRetryService.BASE_RETRY_TIME;

  while (true) {
    yield delay(dayDelay);
    const items = validationRetryService.getPurchases();

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      yield put(actionCreators.validatePurchaseProduct(item));
    }
  }
}

function* validatePurchaseProduct({ payload }) {
  const { purchase } = payload;
  const formData = new FormData();

  Object.keys(purchase).forEach((key) => {
    formData.append(key, purchase[key]);
  });

  const response = yield call(API.purchases.validate, formData);

  // eslint-disable-next-line

  if ([500, 502].includes(response.code)) {
    if (
      (isAndroidPurchase(purchase) && purchase.purchase_token) ||
      (isIosPurchase(purchase) && purchase.receipt)
    ) {
      validationRetryService.addItem(purchase);
    }
  }

  const user = yield select((app: AppState) => app.auth.user);
  rollbar.debug(`Validation billing status: ${response.code}`, {
    ...response,
    purchase,
    userId: user.user_id,
  });

  switch (response.code) {
    case 200: {
      yield put(setRideCreditsLoading(true));
      delay(1000);
      yield put(actionCreators.getSearchStatus.request());
      // #TODO remove?
      yield put(updateRideCredits(response.balance));
      validationRetryService.removePurchase(purchase);
      nativeMessageService.sendValidatePurchaseSuccessMessage(JSON.stringify(purchase));
      break;
    }

    default: {
      nativeMessageService.sendValidatePurchaseFailMessage(
        JSON.stringify({
          response,
          purchase,
        })
      );
    }
  }
  yield put(setRideCreditsLoading(false));
}

async function getCurrentPosition(options = {}) {
  // eslint-disable-next-line no-return-await
  return await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
}

function* sendBackgroundLocation() {
  const minDelay = 1000 * 60;
  let lat;
  let lng;

  while (true) {
    const state: AppState = yield select();

    if (
      state.auth.user === null ||
      state.app.isSearchOn !== true ||
      state.settingsRecommendations.permissions.location >= 1
    ) {
      yield delay(1000 * 2);
      continue;
    }

    try {
      console.log('Try to get location');
      const { coords } = yield getCurrentPosition({
        enableHighAccuracy: true,
        timeout: 1000 * 30,
        maximumAge: 1000 * 30,
      });

      lat = coords.latitude;
      lng = coords.longitude;
    } catch (e) {
      console.error(e);
      yield delay(minDelay);
      continue;
    }

    yield put(
      actionCreators.setBackgroundLocation({
        lat,
        lng,
        sendStatusToNative: false,
      })
    );
    yield delay(minDelay);
  }
}

function* setBackgroundLocation(action) {
  const state: AppState = yield select();

  if (state.auth.user === null || state.app.isSearchOn !== true) {
    return;
  }

  const { lat, lng, sendStatusToNative } = action.payload;
  const formData = new URLSearchParams();

  formData.append('lat', lat);
  formData.append('lng', lng);

  const response = yield call(API.markers.setBackgroundLocation, formData);

  switch (response.code) {
    case 200:
    case 201:
    case 202: {
      if (sendStatusToNative) {
        nativeMessageService.sendBackgroundLocationUpdateMessage(true);
      }

      break;
    }
    case 401: {
      yield put(logout());
      break;
    }
    default: {
      if (sendStatusToNative) {
        nativeMessageService.sendBackgroundLocationUpdateMessage(false);
      }

      break;
    }
  }
}

function* sendLog({ payload }) {
  /* if (isDev) {
    return;
  } */

  const user = yield select((app: AppState) => app.auth.user);

  yield call(API.worker.log, {
    tag: 'App',
    ...payload.data,
    url: window.location.href,
    userId: user?.user_id || 0,
    notification: true,
  });
}

export function* watchSetSearch() {
  yield takeLatest(actionCreators.setSearchStatus.REQUEST, setSearchStatus);
}

export function* watchGetSearch() {
  yield takeLatest(actionCreators.getSearchStatus.REQUEST, getSearchStatus);
}

export function* watchAgreed() {
  yield takeLatest(actionCreators.agreedNewRides.REQUEST, agreedNewRides);
}

export function* watchHandleAppVersion() {
  yield takeLatest(actionCreators.handleAppVersion, handleAppVersion);
}

export function* watchValidatePurchaseProduct() {
  // @ts-ignore
  yield takeEvery(actionCreators.validatePurchaseProduct.type, validatePurchaseProduct);
}
export function* watchToggleShowAllRides() {
  // @ts-ignore
  yield takeEvery(actionCreators.toggleShowAllRides, toggleShowAllRides);
}

export function* watchSetBackgroundLocation() {
  yield takeLatest(actionCreators.setBackgroundLocation, setBackgroundLocation);
}
export function* watchCheckSearchAndRunCallback() {
  yield takeLatest(actionCreators.checkSearchAndRunCallback, checkSearchAndRunCallback);
}
export function* watchSendLog() {
  yield takeEvery(actionCreators.sendLog, sendLog);
}

export default function* flow() {
  yield fork(watchSetSearch);
  yield fork(watchGetSearch);
  yield fork(watchAgreed);
  yield fork(watchHandleAppVersion);
  yield fork(clearDestinationTimes);
  yield fork(clearNotificationAuthToken);
  yield fork(watchValidationRetryItems);
  yield fork(watchValidatePurchaseProduct);
  yield fork(watchToggleShowAllRides);
  // yield fork(sendBackgroundLocation);
  // yield fork(watchSetBackgroundLocation);
  yield fork(watchCheckSearchAndRunCallback);
  yield fork(watchSendLog);
}
